import { q, client } from './queryClient'

const getFirstPage = (collection, size) => client.query(
  q.Map(
    q.Paginate(
      q.Documents(
        q.Collection(collection)
      ),
      { size: size }
    ),
    q.Lambda(
      "ref",
      q.Get(
        q.Var("ref")
      )
    )
  )
)

const getNextPage = (collection, size, after) => client.query(
  q.Map(
    q.Paginate(
      q.Documents(
        q.Collection(collection)
      ),
      { 
        size: size, 
        after: after,
      }
    ),
    q.Lambda(
      "ref",
      q.Get(
        q.Var("ref")
      )
    )
  )
)

const read = {
  first: getFirstPage,
  next: getNextPage,
}

export default read