import React from 'react'

import data from './data'
import ListWithStickyLabel from '../../components/ListWithStickyLabel'
import {
  Header,
  HeaderContent,
  FlexCenter,
} from '../../components/Recycle'

function About() {
  return (
    <>
    <ListWithStickyLabel label={
      (<FlexCenter>
        <Header>
          <HeaderContent>work blurb</HeaderContent>
        </Header>
      </FlexCenter>)
    }>
      <p>{data.workBlurb}</p>
    </ListWithStickyLabel>
    <ListWithStickyLabel label={
      (<FlexCenter>
        <Header>
          <HeaderContent>culture fit</HeaderContent>
        </Header>
      </FlexCenter>)
    }>
      <p>{data.cultureFit}</p>
    </ListWithStickyLabel>
    <ListWithStickyLabel label={
      (<FlexCenter>
        <Header>
          <HeaderContent>code background</HeaderContent>
        </Header>
      </FlexCenter>)
    }>
      <p>{data.codeBackground}</p>
    </ListWithStickyLabel>
    <ListWithStickyLabel label={
      (<FlexCenter>
        <Header>
          <HeaderContent>contact</HeaderContent>
        </Header>
      </FlexCenter>)
    }>
      <p>work email:<br/><br/>joseph.michael.corrado @gmail.com</p>
    </ListWithStickyLabel>
    </>
  )
}

export default About