import React from "react"
import ListWithStickyLabel 
  from "../../components/ListWithStickyLabel"
import data from "./data"

const resumeChildren = data.map((section, index) => {
  const labels = section.labels.map(label => <h4 key={label}>{label}</h4>)
  const items = section.items.map(item => <li key={item}>{item}</li>)

  return <ListWithStickyLabel label={labels} children={items} key={"LWSL" + index}/>
})

export default function Resume() {
  return (
    <>
    {resumeChildren}
    </>
  )
}