const data = {
  definition: `
    a talk, especially an informal one, between two or more 
    people, in which news and ideas are exchanged
  `,
  excitement: `
    i enjoy this definition because of my passion for 
    learning and keeping an open mind, so the idea of 
    ideas being exchanged excites me
  `,
  advocate: `
    if you speak to anyone who knows me well, they can
    tell you that i almost always play devil's advocate, so 
    much so, that i sometimes call it playing joey's advocate,
    and i like to do that because i think its important
    to always understand the other side, if i cannot fathom
    it, then i often question whether my belief is on 
    solid ground or is based in ignorance
  `,
  practice: `
    this practice of mine stems from one of my favorite
    quotes:
  `,
  aristotle: `
    the sign of an educated mind is the ability to 
    entertain a thought without accepting it
  `,
}

export default data