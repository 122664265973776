import React from 'react'
import { animated, useSpring, config } from '@react-spring/web'
import useMeasure from 'react-use-measure'

import { FiPlusCircle, FiMinusCircle } from 'react-icons/fi'

function HorizontalDrawer({
  summary,
  details,
  customKey,
  children,
  active = false,
  makeActive,
}) {
  const [ref, bounds] = useMeasure();
  const { height, opacity, } = useSpring({
    from: {
      height: 0,
      opacity: 0,
    },
    to: {
      height: active ? bounds.height : 0,
      opacity: active ? 1 : 0,
    },
    config: config.slow,
  });

  const handleClick = () => {
    makeActive();
  }

  return (
    <div className="horizontal-drawer" key={customKey}>
      <div className="drawer-summary">
        <button 
          className="drawer-button"
          onClick={handleClick}
        >
          {summary || children[0]}
          <span> {active ? <FiMinusCircle/> : <FiPlusCircle/>} </span>
        </button>
      </div>

      <animated.div 
        style={{  
          height, 
          opacity, 
          overflow: active ? 'unset' : 'hidden',
          // pointerEvents: active ? 'all' : 'none',
        }}
      > 
        <animated.div 
          className="drawer-details"
          ref={ref} 
          children={details || children[1]}
        />
      </animated.div>
    </div>
  )
}

export default HorizontalDrawer