import faunadb from 'faunadb'
const q = faunadb.query

const client = new faunadb.Client({
  secret: 'fnAEbGEsuSACUTqEJv1lxVSwbqxtGaQTxHHqRDTM',
  domain: 'db.fauna.com',
  port: 443,
  scheme: 'https',
})

export { q, client }