import React from "react"
import styled from "styled-components"

export default function ListWithStickyLabel({
  label,
  children,
}) {
  return (
    <Article>
      <LabelWrapper>
        <Label>{label}</Label>
      </LabelWrapper>
      <List>
        {children}
      </List>
    </Article>
  )
}

const Article = styled.article`
  width: stretch;
  margin: calc(var(--NASA) * 3);
  border-radius: var(--NASA);
  background: var(--IVORY);
  color: var(--EBONY);
  display: flex;
  flex-direction: column;

  @media (min-width: 550px) {
    flex-direction: row;
    margin-left: calc(var(--NASA) * 10.5);
    box-shadow: revert;
    overflow: revert;
    gap: calc(var(--NASA) * 3);
  }
`
const LabelWrapper = styled.div`
  margin-left: calc(var(--NASA) * -1.5);
  margin-right: calc(var(--NASA) * -1.5);
  padding: calc(var(--NASA) * 3);
  color: var(--IVORY);
  background: var(--EBONY);
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: var(--NASA);

  @media (min-width: 550px) {
    box-shadow: var(--SHADOW-FULL);
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: calc(var(--NASA) * -7.5);
    margin-right: revert;
  }
`
const Label = styled.label`
  position: sticky;
  top: calc(var(--NASA) * 3);
`
const List = styled.ul`
  flex: 1;
  padding: calc(var(--NASA) * 3);
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: calc(var(--NASA) * 3);

  & > li::before  {
    content: '$ ';
  }

  @media (min-width: 550px) {
    border-radius: var(--NASA) 0 0 var(--NASA);
  }
`