import React, { useContext, useMemo, useReducer } from 'react'

import read from './read'

const FaunaContext = React.createContext()

const types = {
  'FIRST': 'FIRST',
  'NEXT': 'NEXT',
}

function faunaReducer(state, action) {
  switch(action.type) {
    case types.FIRST: {
      return {
        data: action.data,
        after: action.after,
      }
    }
    case types.NEXT: {
      return {
        data: [...state.data, ...action.data],
        after: action.after,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function readFirstQuery(dispatch, collection, size) {
  read.first(collection, size).then(response => {
    dispatch({
      type: types.FIRST,
      after: response.after,
      data: response.data.map(obj => obj.data),
    })
  })
}

function readNextQuery(state, dispatch, collection, size) {
  read.next(collection, size, state.after).then(response => {
    dispatch({
      type: types.NEXT,
      after: response.after,
      data: response.data.map(obj => obj.data),
    })
  })
  console.log(state.data)
}

function FaunaProvider(props) {
  const [state, dispatch] = useReducer(
    faunaReducer, 
    { 
      data: null, 
      after: null,
    }
  )

  const value = useMemo(() => [state, dispatch], [state])

  return <FaunaContext.Provider value={value} {...props}/>
}

function useFauna() {
  const context = useContext(FaunaContext)
  if (!context) {
    throw new Error(`useFauna must be used within a FaunaProvider`)
  }
  const [state, dispatch] = context
  
  const readFirst = (collection, size) => {
    readFirstQuery(dispatch, collection, size)
  }
  const readNext = (collection, size) => {
    readNextQuery(state, dispatch, collection, size)
  }

  return {
    state, 
    dispatch,
    readFirst,
    readNext,
  }
}

export {
  FaunaProvider,
  useFauna,
}