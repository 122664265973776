import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { MdArrowBack, MdArrowForward } from "react-icons/md";

import theJoeGo from "../images/icon.png";
import theProJoe from "../images/profile.jpg";

import GlobalStyles from "../sections/global/GlobalStyles";

import Mission from "../sections/mission/component";
import About from "../sections/about/component";
import WorkHistory from "../sections/workHistory/component";
import ProperNouns from "../sections/properNouns/component";
import Fauna from "../sections/fauna/components";

import HorizontalDrawer from "../components/HorizontalDrawer";

function Index() {
  const [activeImage, setActiveImage] = useState(false);
  const [activeArrow, setActiveIcon] = useState(false);
  const [activeDrawer, setActiveDrawer] = useState(0);
  const refSideScreen = useRef(null);

  const handleArrowClick = () => {
    refSideScreen.current.style.width = !activeArrow ? "100%" : null;
    refSideScreen.current.style.opacity = !activeArrow ? "1" : null;
    setActiveIcon(!activeArrow);
  }

  const handleIconClick = () => {
    setActiveDrawer(activeDrawer >= 5 ? 0 : activeDrawer + 1);
  }

  const handleActiveDrawer = (num) => {
    setActiveDrawer(activeDrawer === num ? 0 : num)
  }

  useEffect(() => setActiveDrawer(0), [activeArrow]);

  return (
    <>
    <Helmet>
      <title>Hello Joe!</title>
      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
      <link href="https://fonts.googleapis.com/css2?family=Rubik+Bubbles&display=swap" rel="stylesheet"/> 
    </Helmet>
    <GlobalStyles/>
    <Wrap id="index">
      <ArrowWrap onClick={handleArrowClick}>
        {activeArrow ? <MdArrowBack/> : <MdArrowForward/>}
      </ArrowWrap>
      {activeArrow && <IconWrap onClick={handleIconClick}>{activeDrawer}</IconWrap>}
      <ImageWrap onClick={() => setActiveImage(!activeImage)}>
        <Image src={activeImage ? theProJoe : theJoeGo} alt="the JoeGo"/>
      </ImageWrap>
      <Welcome>Welcome to the Joe-niverse!</Welcome>
      <SideScreen ref={refSideScreen}>
        <HorizontalDrawer 
          active={5 === activeDrawer}
          makeActive={() => handleActiveDrawer(5)}
        >
          <DrawerHeader>mission</DrawerHeader>
          <Mission/>
        </HorizontalDrawer>
        <HorizontalDrawer 
          active={4 === activeDrawer}
          makeActive={() => handleActiveDrawer(4)}
        >
          <DrawerHeader>about</DrawerHeader>
          <About/>
        </HorizontalDrawer>
        <HorizontalDrawer 
          active={3 === activeDrawer}
          makeActive={() => handleActiveDrawer(3)}
        >
          <DrawerHeader>work history</DrawerHeader>
          <WorkHistory/>
        </HorizontalDrawer>
        <HorizontalDrawer 
          active={2 === activeDrawer}
          makeActive={() => handleActiveDrawer(2)}
        >
          <DrawerHeader>bookmarks</DrawerHeader>
          {/* <ProperNouns/> */}
          <Fauna collection="bookmarks" type="links"/>
        </HorizontalDrawer>
        <HorizontalDrawer 
          active={1 === activeDrawer}
          makeActive={() => handleActiveDrawer(1)}
        >
          <DrawerHeader>quotes</DrawerHeader>
          <Fauna collection="quotes" type="list"/>
        </HorizontalDrawer>
        {/* <HorizontalDrawer 
          active={6 === active}
          makeActive={() => handleActiveDrawer(0)}
        >
          <DrawerHeader>terms</DrawerHeader>
          <Fauna collection="terms" type="list"/>
        </HorizontalDrawer> */}
    </SideScreen>
    </Wrap>
    </>
  )
}

const Wrap = styled.main`
  width: 100%;
  font-family: monospace;
  padding-top: var(--CLAMP);

  & .horizontal-drawer .drawer-summary {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  & .horizontal-drawer .drawer-button {
    color: var(--IVORY);

    &:hover, &:focus {
    outline: none;
    color: var(--EBONY);
    }
  }
`
const ArrowWrap = styled.button`
  width: 40px;
  height: 40px;
  color: var(--CHARCOAL);
  background-color: var(--IVORY);
  border-radius: 50%;
  position: fixed;
  bottom: 12px;
  right: 12px;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
    border: none;
  }

  @media (min-width: 550px) {
    bottom: 12px;
  }
`
const IconWrap = styled.button`
  width: 40px;
  height: 40px;
  color: var(--CHARCOAL);
  background-color: var(--IVORY);
  border-radius: 50%;
  position: fixed;
  bottom: 64px;
  right: 12px;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
    border: none;
  }

  @media (min-width: 550px) {
    bottom: 12px;
  }
`
const ImageWrap = styled.div`
  --SIZE: 16rem;
  width: var(--SIZE);
  height: var(--SIZE);
  margin-top: 0vh;
  margin-bottom: 32px;
  margin-left: auto;
  margin-right: auto;
  background: var(--IVORY);
  border-radius: 50%;
  box-shadow: var(--SHADOW);
  overflow: hidden;
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const Welcome = styled.p`
  padding-top: 48px;
  font-size: 48px;
  font-family: "Rubik Bubbles", Times, serif;
  color: var(--IVORY);
  text-align: center;

  @media (min-width: 550px) {
    padding-top: 72px;
    font-size: 72px;
  }
`;
const SideScreen = styled.div`
  width: 0; 
  height: 100vh; 
  padding: 32px 0; 
  position: fixed; 
  top: 0; 
  left: 0;
  background-color: var(--GOLDEN); 
  overflow-x: hidden;
  opacity: 0; 
  transition: 0.5s; 
`;
const DrawerHeader = styled.h2`
  margin-top: calc(var(--NASA) * 3);
  display: inline-block;
`;

export default Index
