import styled from 'styled-components'

export const Credit = styled.em`
  padding: var(--NASA);
  border-radius: var(--NASA);
  color: var(--CHARCOAL);

  &::before, &::after {
    color: var(--ASHEN);
    font-style: normal;
  }

  &::before {
    content: '[ ';
  }

  &::after {
    content: ' ]';
  }
`
export const HeaderContent = styled(Credit)`
  color: var(--IVORY);
`
export const Header = styled.h2`
  width: fit-content;
`
export const Quote = styled.div`
  padding-left:  8vw;
  padding-right:  10vw;

  &::before {
    content: '"';
  }

  &::after {
    content: '"';
  }
`
export const Ashen = styled.div`
  display: inline-block;
  color: var(--ASHEN);
`
export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`
export const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`