import React from "react"
import styled from "styled-components"

import data from "./data"
import ListWithStickyLabel from "../../components/ListWithStickyLabel"
import {
  Header,
  HeaderContent,
  FlexCenter,
} from '../../components/Recycle'

function ProperNouns() {
  return (
    <>
    {ListWrapper(data.peruse, 'peruse')}
    {ListWrapper(data.portfolio, 'portfolio')}
    {ListWrapper(data.programming, 'programming')}
    {ListWrapper(data.print, 'print')}
    {ListWrapper(data.play, 'play')}
    {ListWrapper(data.people, 'people')}
    {ListWrapper(data.products, 'products')}
    </>
  )
}

function ListWrapper(arr, title) {
  const content = arr.map(item => (
    <Card href={item.href} key={item.name}>
      <ImageWrap>
        <Image src={item.src} alt={item.name}/>
      </ImageWrap>
      <Name>{item.name}</Name>
    </Card>
  ))

  return (
    <ListWithStickyLabel label={
      (<FlexCenter>
        <Header>
          <HeaderContent>{title}</HeaderContent>
        </Header>
      </FlexCenter>)
    }>
      <ContentWrap>{content}</ContentWrap>
    </ListWithStickyLabel>
  )
}

const ContentWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: calc(var(--NASA) * 3);
`
const Card = styled.a.attrs({
  target: "_blank",
})`
  --LOGO-SIZE: calc(var(--NASA) * 16);
  width: fit-content;
  padding: calc(var(--NASA) * 3);
  border-radius: var(--NASA);
  display: flex;
  align-items: center;
  color: var(--BLACK);
  background: var(--WHITE);
  box-shadow: var(--SHADOW-FULL);
  text-decoration: none;
`
const ImageWrap = styled.div`
  width: var(--LOGO-SIZE);
  height: var(--LOGO-SIZE);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`
const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: var(--NASA);
`
const Name = styled.h4`
  padding-left: calc(var(--NASA) * 3);
`

export default ProperNouns;