import React, { useEffect } from 'react'
import styled from 'styled-components'

import { FaunaProvider, useFauna } from './context'
import ListWithStickyLabel from '../../components/ListWithStickyLabel'
import {
  Credit,
  FlexCenter,
  FlexEnd,
  Header,
  HeaderContent,
  Quote,
} from '../../components/Recycle'

const types = {
  LIST: 'list',
  LINKS: 'links',
}

function componentByType(type) {
  switch(type) {
    case types.LIST:
      return FaunaList

    case types.LINKS:
      return FaunaLinks

    default:
      throw new Error(`Unhandled Fauna component type: ${type}`)
  }
}

function Fauna({ collection, type }) {
  const FaunaComponent = componentByType(type)
  
  return (
    <>
    <FaunaProvider>
      <FaunaComponent collection={collection}/>
    </FaunaProvider>
    </>
  )
}

function FaunaList({ collection }) {
  const {
    state,
    readFirst,
    readNext,
  } = useFauna()

  useEffect(() => readFirst(collection, 1), []) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {
        state.data?.map(item => (
          <ListWithStickyLabel 
          key={item.title}
          label={
            <FlexCenter><Header>
              <HeaderContent>{item.title}</HeaderContent>
            </Header></FlexCenter>
          }>
            <Quote>{item.body}</Quote>
            <FlexEnd><Credit>{item.author}</Credit></FlexEnd>
          </ListWithStickyLabel>
        ))
      }
      {/* <Btn onClick={() => readFirst(collection, 1)}>get quotes</Btn> */}
      <Btn 
      onClick={() => readNext(collection, 1)}
      disabled={!Boolean(state.after)}
      >another one</Btn>
      <Btn 
      onClick={() => readNext(collection, 1000)}
      disabled={!Boolean(state.after)}
      >all</Btn>
    </>
  )
}

const Btn = styled.button`
  margin-left: calc(var(--NASA) * 3);
  padding: calc(var(--NASA) * 3);
  border-radius: var(--NASA);
  color: var(--IVORY);
  background: var(--ebony);

  &:disabled {
    opacity: 0.6;
    cursor: auto;
  }
`

function FaunaLinks({ collection }) {
  const {
    state,
    readFirst,
    // readNext,
  } = useFauna()

  useEffect(() => readFirst(collection, 1000), []) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrap>
    <InnerWrap>
    {
      state.data?.map(item => (
        <Link href={item.href}>
          <ImageWrap>
            <Image alt={item.title} src={item.src}/>
          </ImageWrap>
        </Link>
      ))
    }
    </InnerWrap>
    </Wrap>
  )
}

const Wrap = styled.div`
  margin: calc(var(--NASA) * 3);
  scrollbar-color: var(--IVORY) var(--TAN);
  scrollbar-width: none;
  --LOGO-SIZE: calc(var(--NASA) * 24);
`
const InnerWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`
const Link = styled.a.attrs({
  // target: '_blank',
})`
  margin: var(--NASA);
`
const ImageWrap = styled.div`
  width: var(--LOGO-SIZE);
  height: var(--LOGO-SIZE);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: var(--IVORY);
  border-radius: 50%;
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: inherit;
  padding: var(--NASA);
  object-fit: cover;
`

export default Fauna