import React from 'react'

import data from './data'
import ListWithStickyLabel from '../../components/ListWithStickyLabel'
import Spacer from '../../components/Spacer'
import {
  Credit,
  Header,
  HeaderContent,
  Quote,
  Ashen, 
  FlexCenter,
  FlexEnd,
} from '../../components/Recycle'

function Mission() {
  const missionLabel = (
    <FlexCenter>
      <Header>
        <HeaderContent> 
          joe <Ashen>•</Ashen> versation
        </HeaderContent>
      </Header>
    </FlexCenter>
  )

  return (
    <>
    <ListWithStickyLabel label={missionLabel}>
      <div>
        can be defined as:
        <Spacer size={10}/>
        <Quote>{data.definition}</Quote>
        <FlexEnd as="h6">
          <Credit>Oxford Languages</Credit>
        </FlexEnd>
        <Spacer size={30}/>
        {data.excitement}
        <Spacer size={30}/>
        {data.advocate}
        <Spacer size={30}/>
        {data.practice}
        <Spacer size={10}/>
        <Quote>{data.aristotle}</Quote>
        <FlexEnd as="h6">
          <Credit>Aristotle</Credit>
        </FlexEnd>
      </div>
    </ListWithStickyLabel>
    </>
  )
}

export default Mission