const data = [
  // {
  //   labels: [
  //     "Freelance Web Development",
  //     "Furthering"
  //   ],
  //   items: [

  //   ]
  // },
  {
    labels: [
      "Topologe -> Kessel Run (USAF)", 
      "October 2019 - April 2020",
      "Software Engineer (Contractor)",
    ],
    items: [
      `TECH STACK: JavaScript, React, React Java, Spring Boot, PostgreSQL, HTML, CSS, Jest, 
      React Testing Library, GitLab, GitLab CI, Concourse CI, BASH, Pivotal Cloud Foundry, 
      Kubernetes, Webpack, RESTful APIs, Material UI, Formik, Yup, Axon, Lodash, Eventing`,
      `Took on leadership responsibilities among my team, such as: mentoring other engineers
      and supporting them in the process of picking up new skills in tech, especially in React, as
      well as representing the team in anchor meetings, walk-throughs, and town halls`,
      `Created bonds with the Project Manager, Design team, and engineers that I’d like to think
      fostered an environment for communication and trust that helped lead to team success`,
      `Anchored a Jest/React-Testing-Library overhaul of our test coverage, helping to create a
      custom test harness and a team-wide approach to the structure and documentation creation
      process based on the three A’s: Arrange, Act, & Assert (like user story’s Given/When/Then)`,
      `Built a React-based UI using Material-UI components and Formik with Yup validation to
      allow for smooth UX while forming a pleasing frontend design`,
      `Leveraged Spring Boot annotations and JPA to set up and event-driven database to be able
      to create snapshots of data to be able to step through time to see different states of our app`,
      `Transitioned Sass styling to CSS-in-JS to centralize and focus styling to specific
      components allowing the styles to be reused more easily`,
    ]
  },
  {
    labels: [
      "SMEE —> OS Labs, Inc.", 
      "October 2019 - March 2020",
      "Software Engineer",
    ],
    items: [
      `TECH STACK: JavaScript, Node, React, Jest, HTML, CSS, Next.js, PostgreSQL, GraphQL `,
      `Developed a React-focused, modular global state management library utilizing the Flux and Observer patterns, dynamically building
      closure-based globally-accessible state eliminating the need for prop-drilling without complex boilerplate`,
      `Created custom React Hook to establish global state-management library to be used with functional components that work
      efficiently with server side rendering (SSR) as file sizes can be minimized efficiently, reducing client’s load burden`,
      `Benchmarked Redux against SMEE to validate its capabilities to behave consistently, to persist state data, and to work with different
      UI layers, comparing the required boilerplate code and the different structural patterns`,
      `Employed GraphQL to connect relational database (PostgreSQL) to server, sending queries to test SMEE’s handling of rich data that
      persisted safely outside of the environment of the application in an effort to increase local state speed`,
      `Used Webpack and Babel to minify and uglify JavaScript files, transpiling ES6+ syntax to ES5 to ensure compatibility`,
      `Implemented Next.js to understand how a developed global state framework worked with server-side rendering`,
      `Structured NPM package to ensure viable global state by initializing subscribers to only requested pieces of state from global store to
      listen for changes and run the necessary functions to generate a new observable and re-render the component`,
    ],
  },
  {
    labels: [
      "PUBLIC TALKS",
    ],
    items: [
      `ENTER WEB COMPONENTS / Build with Code NYC (2019) -
      Presented new possibilities from the growth of web components with and without other popular frameworks`,
      `STREAMLINING STATE /
      Build with Code NYC / 2020 - Introduced SMEE as competitor to Redux and demonstrated the
      improvement of first meaningful paint`,
      // `HARNESSING REACT / Kessel Run (2021) - Introduced my approach to
      // testing our React components with a custom test harness`,
    ],
  },
]

export default data