const data = {
  workBlurb: `
    The role I last worked, with Topologe and the United 
    States Air Force, was as a senior full-stack developer. Early 
    on I worked 50/50 frontend, primarily with JavaScript and 
    React, and backend, primarily with Java and Spring Boot. As 
    time went on I became the lead engineer for the UI, which 
    meant I was architecting new code as well as refactoring 
    pre-existing code to work more effectively. (I always like 
    to keep an open mind that existing code can be written 
    better). I also was mentoring/teaching the other engineers 
    how to work in the frontend with React. I was working 
    with a lot of people who had 
    never worked on a UI, and often never worked with 
    JavaScript or React. I also represented the team in 
    meetings with other engineers and tech leads. I worked 
    closely with the project manager and designer to help 
    write stories. The position was technically a DevSecOps 
    role so I also had 
    to work with technologies to secure and deliver the 
    product. I helped set up our CI/CD pipeline in GitLab as 
    well as working with Fortify, SNYK, and SonarQube to make 
    sure our code was secure. 
    `,
  cultureFit: `
    As a person I’d like to think I’d be a great fit in any 
    culture that I'd be interested in joining because I love 
    people. My degree is not Computer 
    Science, but I did focus on diversity, history, and 
    sociology. I used college as a time to really grow as a 
    human being and learn about different people/cultures 
    from my own. I’d like to think that has made me a better 
    listener and has opened my mind to new ideas, which helps 
    in a workplace. 
    I try to give others the chance to be heard because often 
    times people will come up with brilliant ideas that may 
    only be because they thought about a single part of a 
    complex problem slightly differently. People really are 
    amazing. I love to learn and to have the opportunity to 
    continue growing as a human being. 
    `,
  codeBackground: `
    Some more background on me is that I found my love for 
    code relatively late in my life, but I researched and 
    found the best coding school that specialized in 
    technical communication and working with others. Then I 
    busted my butt, learned a lot, and eventually worked my 
    way into a Senior Software Engineer role in only two 
    years. I know that employers are often scared of the 
    limited experience, but if you invest in me, it will be 
    worth it! I obviously still have a lot to learn, as we 
    all do, but you will see that my potential is high to 
    grow a lot more as the smart, efficient, full-stack 
    developer that I already am. (Confident and humble too!)
    `,
}

export default data